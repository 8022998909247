export enum MenuLocationEnum {
    header = 'header',
    footer = 'footer',
}
export interface MenuPage {
    id?: string;
    menu_location: MenuLocationEnum;
    title: string;
    content?: string | null;
    meta_description?: string | null;
    meta_keywords?: string | null;
    meta_title?: string | null;
    slug?: string | null;
    redirectUrl?: string | null;
    order: number;
    column: number;
    created_at?: Date;
    updated_at?: Date;
    includedInHeader: boolean;
}
