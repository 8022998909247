export enum ModelsEnum {
    USERS = 'Utilizatori',
    CLIENTS = 'Clienti',
    CATEGORIES = 'Categorii',
    ADDRESSES = 'Adrese',
    CONTACTS = 'Contacte',
}

export const ModelsEnumValues: any = {
    [ModelsEnum.USERS]: [
        {
            field: 'Nume',
            value: 'firstname',
            required: true,
            default: null,
            matchFrom: [],
        },
        {
            field: 'Prenume',
            value: 'lastname',
            required: true,
            default: null,
            matchFrom: [],
        },
        {
            field: 'Email',
            value: 'email',
            required: true,
            default: null,
            matchFrom: [],
        },
        {
            field: 'Telefon',
            value: 'phone',
            required: true,
            default: null,
            matchFrom: [],
        },
        {
            field: 'Abonat la newsletter',
            value: 'newsletter',
            required: false,
            default: 'DA',
            matchFrom: [],
        },
        {
            field: 'Activ',
            value: 'active',
            required: false,
            default: 'DA',
            matchFrom: [],
        },
    ],
};
