/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum WebcoffeeRoutes {
    home = '',
    userProfile = 'user',
    login = 'login',
    register = 'register',
    users = 'utilizatori',
    magentoCustomers = 'clienti',
    magentoOrders = 'comenzi',
    magentoProducts = 'produse',
    magentoInventory = 'inventar',
    magentoAddresses = 'adrese',
    customers = 'clienti',
    addresses = 'adrese',
    contacts = 'contacte',
    products = 'produse',
    providers = 'furnizori',
    providerItems = 'produse-furnizor',
    providerLogs = 'jurnal-furnizori',
    details = 'detalii',
    tags = 'taguri',
    assets = 'fisiere',
    categories = 'categorii',
    importWizard = 'import-wizard',
    pages = 'pagini',
    detailSets = 'seturi-detalii',
    stocks = 'stocuri',
    orders = 'comenzi',
    frontPageProducts = 'produse-prima-pagina',
    emails = 'emails',
    dls = 'liste-de-distributie',
    productRequests = 'cerere-produse',
    tools = 'tools',
    tasks = 'tasks',
    redirects = 'redirects',
    jobs = 'jobs',
    brands = 'brands',
    rolesPermissions = 'roluri-si-permisiuni',
    banners = 'banners',
}

export enum WebcoffeeLinks {
    home = '',
    userProfile = '/user',
    login = '/login',
    register = '/register',
    users = '/utilizatori',
    magentoCustomers = '/magento/clienti',
    magentoOrders = '/magento/comenzi',
    magentoProducts = '/magento/produse',
    magentoInventory = '/magento/inventar',
    magentoAddresses = '/magento/adrese',
    customers = '/clienti',
    addresses = '/adrese',
    contacts = '/contacte',
    products = '/produse',
    details = '/detalii',
    providers = '/furnizori',
    providerItems = '/produse-furnizor',
    providerLogs = '/jurnal-furnizori',
    tags = '/taguri',
    assets = '/fisiere',
    categories = '/categorii',
    importWizard = '/import-wizard',
    pages = '/pagini',
    detailSets = '/seturi-detalii',
    stocks = '/smartbill/stocuri',
    orders = '/comenzi',
    frontPageProducts = '/produse-prima-pagina',
    emails = '/emails',
    dls = '/liste-de-distributie',
    productRequests = '/cerere-produse',
    tools = '/tools',
    tasks = '/tasks',
    redirects = '/redirects',
    jobs = '/jobs',
    brands = '/brands',
    rolesPermissions = '/roluri-si-permisiuni',
    banners = '/banners',
}
