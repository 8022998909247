import { Asset } from './asset';
import { CartProduct } from './cart-product';
import { Category } from './category';
import { CurrencyEnum } from './currency';
import { ProductDetail } from './product-detail';
import { ProviderItem } from './provider';
import { Set } from './set';
import { Stock } from './stock';

export interface Brand {
    id?: string;
    name: string;
    logo?: string;
    description?: string;
    meta_title?: string;
    meta_keywords?: string;
    meta_description?: string;
    slug?: string;
}
export interface Price {
    id?: string;
    price: number;
    discounted_price: number | null;
    currency: CurrencyEnum;
    exchangeRate: number | null;
    base_price: number;
    suggested_price: number | null;
    taxId: string | null;
    discounted_base_price: number | null;
    discounted_price_from: string | null;
    discounted_price_to: string | null;
    new_from: string | null;
    new_to: string | null;
    product?: Product;
    tax?: Tax;
    shipping_cost?: number;
    additional_taxes?: any;
}

export interface Product {
    id?: string;
    name: string;
    description?: string | null;
    short_description?: string | null;
    sku: string;
    ean?: string;
    expiresAt?: string | null;
    brandId?: string;
    brand?: Brand;
    meta_description?: string | null;
    meta_keywords?: string | null;
    meta_title?: string | null;
    slug?: string | null;
    status: ProductStatusEnum;
    setId?: string | null;
    stockId?: string | null;
    created_at?: Date;
    updated_at?: Date;
    priceId: string;
    recommended: boolean;
    price: Price;
    categories?: CategoryProduct[];
    product_details?: ProductDetail[];
    assets: Asset[];
    set?: Set;
    stock?: Stock;
    cart_products?: CartProduct[];
    related_products?: RelatedProduct[];
    provider_items?: ProviderItem[];
}

export enum RelatedProductTypeEnum {
    Similar = 'Similar',
    Compatibil = 'Compatibil',
}

export interface RelatedProduct {
    productId?: string;
    relatedProductId: string;
    type: RelatedProductTypeEnum;
    product: Product;
    related_product: Product;
}

export interface Tax {
    id?: string;
    name: string;
    title?: string | null;
    rate: number;
    product_prices?: Price[];
}

export enum FrontPageSectionEnum {
    Promotii = 'Promotii',
    Recomandate = 'Recomandate',
    Produse_noi = 'Produse_noi',
}

export interface FrontPageProduct {
    id?: string;
    desktop: boolean;
    mobile: boolean;
    order: number;
    productId?: string | null;
    section: FrontPageSectionEnum;
    product: Product;
}

export interface CategoryProduct {
    categoryId?: string;
    productId?: string;
    category: Category;
    product: Product;
}

export enum ProductStatusEnum {
    La_comanda = 'La_comanda',
    In_stoc = 'In_stoc',
    In_stoc_furnizor = 'In_stoc_furnizor',
    In_stoc_furnizor_extern = 'In_stoc_furnizor_extern',
    Stoc_limitat = 'Stoc_limitat',
    Indisponibil_temporar = 'Indisponibil_temporar',
    Dezactivat = 'Dezactivat',
}
