import { DateTime } from 'luxon';
import { Order, OrderItem } from './order';

export enum PackageTypeEnum {
    COLET = 0,
    PLIC = 1,
    COLET_MARE = 2,
}

export const PackageTypeName = {
    [PackageTypeEnum.COLET]: 'Colet',
    [PackageTypeEnum.PLIC]: 'Plic',
    [PackageTypeEnum.COLET_MARE]: 'Colet mare',
};

export enum AWBPaymentEnum {
    CLIENT = 1,
    DESTINATAR = 2,
    TERT = 3,
}

export const AWBPaymentName = {
    [AWBPaymentEnum.CLIENT]: 'Client',
    [AWBPaymentEnum.DESTINATAR]: 'Destinatar',
    [AWBPaymentEnum.TERT]: 'Tert',
};

export interface ThirdParty {
    county: number;
    city: number;
    address: string;
    name: string;
    phoneNumber: string;
    personType: boolean;
    cityString: string;
    countyString: string;
    companyName: string;
    companyCui: string;
    companyOnrcNumber: string;
    companyIban: string;
    companyBank: string;
}

export interface AwbRecipient {
    name: string;
    phoneNumber: string;
    personType: boolean;
    companyName: string;
    companyCui: string;
    companyOnrcNumber: string;
    companyIban: string;
    companyBank: string;
    postalCode: string;
    county: number;
    city: number;
    cityString: string;
    countyString: string;
    address: string;
}

export interface Parcel {
    weight: number;
    width: number;
    length: number;
    height: number;
    awbParcelNumber: number;
    status: any;
    products?: OrderItem[];
    statusInfo?: ParcelStatus;
    statusHistory?: ParcelStatus[];
}

export interface ParcelStatus {
    name: string;
    label: string;
    state: string;
    status: ShippingStatusEnum;
    transitLocation: string;
    county: string;
    statusDate: DateTime;
}

export enum ShippingStatusEnum {
    GENERATED = 'AWB Generat',
    IN_PROGRESS = 'In curs de livrare',
    CLOSED = 'Finalizat',
}

export enum CourierTypeEnum {
    SAMEDAY = 'Sameday',
    DRAGON_STAR = 'DragonStar',
    CARGUS = 'Cargus',
    HENDI = 'Hendi',
}

export interface Shipping {
    id?: string;
    contact_person?: string | null;
    packageNumber: number;
    packageWeight: number;
    service?: string | null;
    cashOnDelivery?: number | null;
    cashOnDeliveryReturns?: boolean | null;
    insuredValue?: number | null;
    thirdPartyPickup?: boolean | null;
    serviceTaxes?: any | null;
    deliveryInterval?: string | null;
    observation?: string | null;
    priceObservation?: string | null;
    clientInternalReference?: string | null;
    clientOsbservation?: string | null;
    awbNumber?: string | null;
    awbDocument?: string | null;
    created_at?: Date;
    updated_at?: Date;
    orderId?: string | null;
    cost: number;
    status: string;
    packageType: PackageTypeEnum;
    awbPayment?: AWBPaymentEnum;
    thirdParty?: ThirdParty;
    awbRecipient: AwbRecipient;
    parcels?: Parcel[];
    order?: Order;
    courierType: CourierTypeEnum;
}
