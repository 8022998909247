export enum LinkAction {
    ADD = 'add',
    IMPORT = 'import',
    DELETE = 'delete',
    CUSTOM = 'custom',
}

interface NavigationLink {
    link?: string[] | null;
    text?: string | null;
    import?: boolean;
    icon?: string | null;
    emit?: LinkAction | null;
    classes?: string;
}

export interface NavigationLinks {
    left: NavigationLink[];
    right: NavigationLink[];
}
