import { User } from './user';

export enum TaskTypeEnum {
    BUG = 'bug',
    FEATURE = 'feature',
    TASK = 'task',
}

export enum TaskPriorityEnum {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    URGENT = 'urgent',
}

export enum TaskStatusEnum {
    NEW = 'new',
    REVIEW = 'review',
    DEV = 'dev',
    VERIFICATION = 'verification',
    CLOSED = 'closed',
}

export interface TaskAttachment {
    id?: string;
    taskId: string;
    task: Task;
    attachment: string;
    uploaderId?: string;
    uploader: User;
}

export interface TaskReminder {
    id?: string;
    message?: string;
    date: Date;
    task: Task;
}

export interface TaskComment {
    id?: string;
    taskId: string;
    task: Task;
    userId: string;
    user: User;
    text: string;
    commentId?: string;
    comment?: TaskComment;
    comments: TaskComment[];
    created_at: string;
    updated_at: string;
}

export interface Task {
    id?: string;
    title: string;
    description: string;
    type: TaskTypeEnum;
    priority: TaskPriorityEnum;
    assigneeId?: string;
    assignee?: User;
    resolution?: string;
    status: TaskStatusEnum;
    reporterId: string;
    reporter: User;
    attachments: TaskAttachment[];
    reminderId: string;
    reminder: TaskReminder;
    comments: TaskComment[];
}
