import { DateTime } from 'luxon';
import { CurrencyEnum } from './currency';
import { Order } from './order';

export interface ProductInvoice {
    name: string;
    code: string;
    productDescription?: string;
    isDiscount?: boolean;
    measuringUnitName: string;
    currency: CurrencyEnum;
    quantity?: number;
    numberOfItems?: number;
    price?: number | string;
    isTaxIncluded?: boolean;
    taxName?: string;
    taxPercentage?: number | string;
    exchangeRate?: number;
    saveToDb?: boolean;
    discountType?: number;
    discountValue?: number;
    warehouseName?: string;
    isService?: boolean;
}

export interface ClientInvoice {
    name: string;
    vatCode?: string;
    code?: string;
    address?: string;
    regCom?: string;
    isTaxPayer?: boolean;
    contact?: string;
    phone?: string;
    city?: string;
    county?: string;
    country?: string;
    email?: string;
    bank?: string;
    iban?: string;
    saveToDb?: boolean;
}

export enum InvoicePaymentTypeEnum {
    Chitanta = 'Chitanta',
    Card = 'Card',
    CEC = 'CEC',
    Bilet = 'Bilet ordin',
    Ordin = 'Ordin plata',
    Mandat = 'Mandat postal',
    Alta = 'Alta incasare',
}

export interface InvoicePayment {
    issueDate?: DateTime;
    currency?: CurrencyEnum;
    seriesName?: string;
    exchangeRate?: number;
    value: number;
    text?: string;
    translatedText?: string;
    type: InvoicePaymentTypeEnum;
    isCash?: boolean;
    observation?: string;
    useInvoiceDetails?: boolean;
    invoicesList?: { seriesName: string; number: number }[];
}

export enum InvoiceTypeEnum {
    Proforma = 'Proforma',
    Factura = 'Factura',
    Hendi = 'Hendi',
    Storno = 'Storno',
}

export enum InvoiceCodeTypeEnum {
    TRANSPORT = 'WEB1',
    RAMBURS = 'WEB2',
    AMBALARE = 'WEB3',
    SGR = 'SGR',
}

export const InvoiceCodeName: Record<string, string> = {
    [InvoiceCodeTypeEnum.TRANSPORT]: 'Cost transport',
    [InvoiceCodeTypeEnum.RAMBURS]: 'Cost procesare plata',
    [InvoiceCodeTypeEnum.AMBALARE]: 'Cost ambalare',
    [InvoiceCodeTypeEnum.SGR]: 'Cost SGR',
};

export interface Invoice {
    id?: string;
    isDraft: boolean;
    issueDate: string;
    seriesName: string;
    documentNumber?: string | null;
    currency?: CurrencyEnum | null;
    exchangeRate?: number | null;
    issuerCnp?: string | null;
    issuerName?: string | null;
    aviz?: string | null;
    dueDate?: string | null;
    mentions?: string | null;
    observations?: string | null;
    delegateAuto?: string | null;
    delegateIdentityCard?: string | null;
    delegateName?: string | null;
    deliveryDate?: string | null;
    paymentDate?: string | null;
    useStock?: boolean | null;
    useEstimateDetails?: boolean | null;
    usePaymentTax?: boolean | null;
    paymentBase?: number | null;
    colectedTax?: number | null;
    paymentTotal?: number | null;
    documentUrl?: string | null;
    invoiceType: InvoiceTypeEnum;
    created_at?: Date;
    updated_at?: Date;
    orderId?: string | null;
    cancelDate?: string | null;
    restoreDate?: string | null;
    reverseDate?: string | null;
    payment?: InvoicePayment;
    client?: ClientInvoice;
    order?: Order;
    products?: ProductInvoice[];
}
