import { Product } from './product';

export enum AssetTypeEnum {
    poza = 'poza',
    document = 'document',
    video = 'video',
}
export interface Asset {
    id?: string;
    url: string;
    local: boolean;
    type: AssetTypeEnum;
    productId?: string | null;
    created_at?: Date;
    updated_at?: Date;
    default: boolean;
    product?: Product;
}
