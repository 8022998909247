export enum CurrencyCode {
    RON = 946,
    EUR = 978,
    USD = 840,
}

export enum CurrencyEnum {
    RON = 'RON',
    EUR = 'EUR',
    USD = 'USD',
}

export interface Currency {
    id?: string;
    rates: any;
    created_at?: string;
}
