import { Client } from './order';
import { Product } from './product';

export enum ProductRequestStatusEnum {
    Noua = 'Noua',
    Rezolvata = 'Rezolvata',
}
export interface ProductRequest {
    id?: number;
    file?: string | null;
    message: string;
    created_at?: Date;
    updated_at?: Date;
    status: ProductRequestStatusEnum;
    client: Client;
    products?: { product: Product; productId?: string; productRequestId?: string }[];
}
