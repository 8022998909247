import { Shipping } from './shipping';
import { Cart } from './cart';
import { Customer } from './customer';
import { Invoice } from './invoice';
import { Product } from './product';
import { Payment } from './payment';
import { User } from './user';

export interface OrderItem {
    id?: string;
    name: string;
    sku: string;
    provider_name: string | null;
    provider_price: number | null;
    price: number;
    discounted_price?: number | null;
    quantity: number;
    productId?: string | null;
    tax_percentage: number;
    orderId?: string | null;
    product?: Product;
    order?: Order;
    shipping_cost?: number | null;
    additional_taxes?: any;
}

export interface Client {
    contact: string;
    email?: string | null;
    phone: string;
    city: string;
    country: string;
    address: string;
    street?: string;
    number?: string;
    postal_code?: string;
    code?: string | null;
    name: string;
    vatCode?: string | null;
    regCom?: string | null;
    bank?: string | null;
    iban?: string | null;
    county: string;
    saveToDb?: boolean;
    isTaxPayer: boolean;
    personType: boolean;
}

export interface ShippingContact {
    name: string;
    email?: string;
    phone: string;
}

export interface ShippingAddress {
    city: string;
    street: string;
    county?: string;
    number?: string;
    postal_code?: string;
    details?: string;
    latitude?: number;
    longitude?: number;
}
export interface ShippingDetails {
    contact: ShippingContact;
    address: ShippingAddress;
}

export enum OrderStatusEnum {
    Noua = 'Noua',
    In_asteptare = 'In_asteptare',
    In_procesare = 'In_procesare',
    Asteptare_livrare = 'Asteptare_livrare',
    Asteptare_ridicare = 'Asteptare_ridicare',
    In_livrare_la_curier = 'In_livrare_la_curier',
    Livrata_partial = 'Livrata_partial',
    Livrata = 'Livrata',
    Anulata = 'Anulata',
    Asteptare_plata = 'Asteptare_plata',
    Platita = 'Platita',
    Returnata_partial = 'Returnata_partial',
    Returnata = 'Returnata',
    Anulata_de_client = 'Anulata_de_client',
    Finalizata = 'Finalizata',
    Asteptare_verificare_manuala = 'Asteptare_verificare_manuala',
    Contestata = 'Contestata',
}

export enum OrderPriorityEnum {
    Scazuta = 'Scazuta',
    Medie = 'Medie',
    Crescuta = 'Crescuta',
    Urgenta = 'Urgenta',
}

export enum PaymentTypeEnum {
    Ramburs = 'Ramburs',
    Ordin_de_plata = 'Ordin_de_plata',
    Card = 'Card',
}

export interface OrderNote {
    id?: string;
    created_at?: Date;
    updated_at?: Date;
    orderId?: string;
    order?: Order;
    userId?: string;
    user?: User;
    note: string;
    alert: string;
}

export interface Order {
    customer_order_id: string;
    status: OrderStatusEnum;
    created_at?: Date;
    updated_at?: Date;
    customerId?: string | null;
    priority: OrderPriorityEnum;
    total: number;
    shipping_cost: number;
    quantity?: number | null;
    id?: string;
    paymentType: PaymentTypeEnum;
    cart?: Cart;
    customer?: Customer;
    client?: Client;
    shippingDetails: ShippingDetails;
    scheduledShipping?: string | null;
    lastModifiedBy?: string;
    invoices?: Invoice[];
    shippings?: Shipping[];
    products?: OrderItem[];
    payments?: Payment[];
    notes: OrderNote[];
}
