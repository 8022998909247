export enum WebsocketEvent {
    UNREAD_NOTIFICATIONS = 'unread-notifications',
    NOTIFICATION = 'notification',
    PROVIDERS = 'providers',
}

export enum WebsocketNotificationName {
    USER = 'user',
    PRODUCT_CHANGE = 'productChange',
    INDEXING_PRODUCT = 'indexingProduct',
    OPERATION_STATUS = 'operationStatus',
    ORDERS = 'orders',
    ORDER_CREATE = 'order_create',
    ORDER_UPDATE = 'order_update',
    ORDER_DELETE = 'order_delete',
    CLIENT_ORDER_CREATE = 'client_order_create',
    CLIENT_ORDER_CANCELED = 'client_order_canceled',
    PRODUCT_CREATE = 'product_create',
    PRODUCT_UPDATE = 'product_update',
    PRODUCT_DELETE = 'product_delete',
    UPDATE_ORDER = 'update_order',
    GENERATE_AWB = 'generate_awb',
    GENERATE_INVOICE = 'generate_invoice',
    INVOICE_DELETE = 'invoice_delete',
    ORDER_PAYMENT_SUCCESS = 'order_payment_success',
    ORDER_PAYMENT_FAILED = 'order_payment_failed',
    ORDER_DEPOSIT_SUCCESS = 'order_deposit_success',
    ORDER_DEPOSIT_FAILED = 'order_deposit_failed',
    ORDER_LOCAL_INVOICE = 'order_local_invoice',
    ORDER_DROPSHIPPING_INVOICE = 'order_dropshipping_invoice',
    ORDER_HENDI_INVOICE = 'order_hendi_invoice',
    ORDER_SHIPPING_CLOSED = 'order_shipping_closed',
    ORDER_OLDER_24H = 'order_older_24h',
    ORDER_NOT_SHIPPED_48H = 'order_not_shipped_48h',
    AWB_NOT_PICKED_UP = 'awb_not_picked_up',
    OFFER_REQUEST = 'offer_request',
    NO_STOCK = 'no_stock',
}

export enum ProvidersNotificationName {
    StartSyncProvider = 'Start',
    EndSyncProvider = 'Terminat',
    SyncProviderProgress = 'Progress',
    SyncProviderError = 'Eroare',
    ProviderItemUpsert = 'Update/inserare produs furnizor',
    ProviderItemUpsertError = 'Eroare update/inserare produs furnizor',
    SyncFeedMandatoryNotValid = 'Sync feeduri campuri nevalide',
    SyncProviderMarkAllUnavailable = 'Marcare toate ca indisponibile',
    SyncProviderMarkAllUnavailableError = 'Eroare marcare toate ca indisponibile',
    SyncProviderMarkUnavailableProduct = 'Marcare produs ca indisponibil',
    SyncProviderMarkUnavailableProductError = 'Eroare marcare produs ca indisponibil',
    SyncProviderIndexUnavailable = 'Marcare ca indisponibil',
    SyncProviderChangeItemStatus = 'Schimbare status produs furnizor',
    DeactivateProviderProduct = 'Dezactivare produs furnizor',
    DeactivateProviderProductError = 'Eroare dezactivare produs furnizor',
    ProductUpdate = 'Update produs',
    ProductUpdateError = 'Eroare update produs',
}
