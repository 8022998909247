export const unicodeToAsciiMap: any = {
    '«': '"',
    '²': '2',
    '³': '3',
    '¹': '1',
    '»': '"',
    À: 'A',
    Á: 'A',
    Â: 'A',
    Ã: 'A',
    Ä: 'A',
    Å: 'A',
    Æ: 'AE',
    Ç: 'C',
    È: 'E',
    É: 'E',
    Ê: 'E',
    Ë: 'E',
    Ì: 'I',
    Í: 'I',
    Î: 'I',
    Ï: 'I',
    Ð: 'D',
    Ñ: 'N',
    Ò: 'O',
    Ó: 'O',
    Ô: 'O',
    Õ: 'O',
    Ö: 'O',
    Ø: 'O',
    Ù: 'U',
    Ú: 'U',
    Û: 'U',
    Ü: 'U',
    Ý: 'Y',
    Þ: 'TH',
    ß: 'ss',
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a',
    æ: 'ae',
    ç: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ð: 'd',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ø: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    þ: 'th',
    ÿ: 'y',
    Ā: 'A',
    ā: 'a',
    Ă: 'A',
    ă: 'a',
    Ą: 'A',
    ą: 'a',
    Ć: 'C',
    ć: 'c',
    Ĉ: 'C',
    ĉ: 'c',
    Ċ: 'C',
    ċ: 'c',
    Č: 'C',
    č: 'c',
    Ď: 'D',
    ď: 'd',
    Đ: 'D',
    đ: 'd',
    Ē: 'E',
    ē: 'e',
    Ĕ: 'E',
    ĕ: 'e',
    Ė: 'E',
    ė: 'e',
    Ę: 'E',
    ę: 'e',
    Ě: 'E',
    ě: 'e',
    Ĝ: 'G',
    ĝ: 'g',
    Ğ: 'G',
    ğ: 'g',
    Ġ: 'G',
    ġ: 'g',
    Ģ: 'G',
    ģ: 'g',
    Ĥ: 'H',
    ĥ: 'h',
    Ħ: 'H',
    ħ: 'h',
    Ĩ: 'I',
    ĩ: 'i',
    Ī: 'I',
    ī: 'i',
    Ĭ: 'I',
    ĭ: 'i',
    Į: 'I',
    į: 'i',
    İ: 'I',
    ı: 'i',
    Ĳ: 'IJ',
    ĳ: 'ij',
    Ĵ: 'J',
    ĵ: 'j',
    Ķ: 'K',
    ķ: 'k',
    ĸ: 'q',
    Ĺ: 'L',
    ĺ: 'l',
    Ļ: 'L',
    ļ: 'l',
    Ľ: 'L',
    ľ: 'l',
    Ŀ: 'L',
    ŀ: 'l',
    Ł: 'L',
    ł: 'l',
    Ń: 'N',
    ń: 'n',
    Ņ: 'N',
    ņ: 'n',
    Ň: 'N',
    ň: 'n',
    ŉ: 'n',
    Ŋ: 'N',
    ŋ: 'n',
    Ō: 'O',
    ō: 'o',
    Ŏ: 'O',
    ŏ: 'o',
    Ő: 'O',
    ő: 'o',
    Œ: 'OE',
    œ: 'oe',
    Ŕ: 'R',
    ŕ: 'r',
    Ŗ: 'R',
    ŗ: 'r',
    Ř: 'R',
    ř: 'r',
    Ś: 'S',
    ś: 's',
    Ŝ: 'S',
    ŝ: 's',
    Ş: 'S',
    ş: 's',
    Š: 'S',
    š: 's',
    Ţ: 'T',
    ţ: 't',
    Ť: 'T',
    ť: 't',
    Ŧ: 'T',
    ŧ: 't',
    Ũ: 'U',
    ũ: 'u',
    Ū: 'U',
    ū: 'u',
    Ŭ: 'U',
    ŭ: 'u',
    Ů: 'U',
    ů: 'u',
    Ű: 'U',
    ű: 'u',
    Ų: 'U',
    ų: 'u',
    Ŵ: 'W',
    ŵ: 'w',
    Ŷ: 'Y',
    ŷ: 'y',
    Ÿ: 'Y',
    Ź: 'Z',
    ź: 'z',
    Ż: 'Z',
    ż: 'z',
    Ž: 'Z',
    ž: 'z',
    ſ: 's',
    ƀ: 'b',
    Ɓ: 'B',
    Ƃ: 'B',
    ƃ: 'b',
    Ɔ: 'O',
    Ƈ: 'C',
    ƈ: 'c',
    Ɖ: 'D',
    Ɗ: 'D',
    Ƌ: 'D',
    ƌ: 'd',
    Ǝ: 'E',
    Ə: 'A',
    Ɛ: 'E',
    Ƒ: 'F',
    ƒ: 'f',
    Ɠ: 'G',
    ƕ: 'hv',
    Ɩ: 'I',
    Ɨ: 'I',
    Ƙ: 'K',
    ƙ: 'k',
    ƚ: 'l',
    Ɯ: 'M',
    Ɲ: 'N',
    ƞ: 'n',
    Ɵ: 'O',
    Ơ: 'O',
    ơ: 'o',
    Ƥ: 'P',
    ƥ: 'p',
    ƫ: 't',
    Ƭ: 'T',
    ƭ: 't',
    Ʈ: 'T',
    Ư: 'U',
    ư: 'u',
    Ʋ: 'V',
    Ƴ: 'Y',
    ƴ: 'y',
    Ƶ: 'Z',
    ƶ: 'z',
    ƿ: 'w',
    Ǆ: 'DZ',
    ǅ: 'Dz',
    ǆ: 'dz',
    Ǉ: 'LJ',
    ǈ: 'Lj',
    ǉ: 'lj',
    Ǌ: 'NJ',
    ǋ: 'Nj',
    ǌ: 'nj',
    Ǎ: 'A',
    ǎ: 'a',
    Ǐ: 'I',
    ǐ: 'i',
    Ǒ: 'O',
    ǒ: 'o',
    Ǔ: 'U',
    ǔ: 'u',
    Ǖ: 'U',
    ǖ: 'u',
    Ǘ: 'U',
    ǘ: 'u',
    Ǚ: 'U',
    ǚ: 'u',
    Ǜ: 'U',
    ǜ: 'u',
    ǝ: 'e',
    Ǟ: 'A',
    ǟ: 'a',
    Ǡ: 'A',
    ǡ: 'a',
    Ǣ: 'AE',
    ǣ: 'ae',
    Ǥ: 'G',
    ǥ: 'G',
    Ǧ: 'G',
    ǧ: 'G',
    Ǩ: 'K',
    ǩ: 'k',
    Ǫ: 'O',
    ǫ: 'o',
    Ǭ: 'O',
    ǭ: 'o',
    ǰ: 'j',
    Ǳ: 'DZ',
    ǲ: 'Dz',
    ǳ: 'dz',
    Ǵ: 'G',
    ǵ: 'g',
    Ƕ: 'HV',
    Ƿ: 'W',
    Ǹ: 'N',
    ǹ: 'n',
    Ǻ: 'A',
    ǻ: 'a',
    Ǽ: 'AE',
    ǽ: 'ae',
    Ǿ: 'O',
    ǿ: 'o',
    Ȁ: 'A',
    ȁ: 'a',
    Ȃ: 'A',
    ȃ: 'a',
    Ȅ: 'E',
    ȅ: 'e',
    Ȇ: 'E',
    ȇ: 'e',
    Ȉ: 'I',
    ȉ: 'i',
    Ȋ: 'I',
    ȋ: 'i',
    Ȍ: 'O',
    ȍ: 'o',
    Ȏ: 'O',
    ȏ: 'o',
    Ȑ: 'R',
    ȑ: 'r',
    Ȓ: 'R',
    ȓ: 'r',
    Ȕ: 'U',
    ȕ: 'u',
    Ȗ: 'U',
    ȗ: 'u',
    Ș: 'S',
    ș: 's',
    Ț: 'T',
    ț: 't',
    Ȝ: 'Z',
    ȝ: 'z',
    Ȟ: 'H',
    ȟ: 'h',
    Ƞ: 'N',
    ȡ: 'd',
    Ȣ: 'OU',
    ȣ: 'ou',
    Ȥ: 'Z',
    ȥ: 'z',
    Ȧ: 'A',
    ȧ: 'a',
    Ȩ: 'E',
    ȩ: 'e',
    Ȫ: 'O',
    ȫ: 'o',
    Ȭ: 'O',
    ȭ: 'o',
    Ȯ: 'O',
    ȯ: 'o',
    Ȱ: 'O',
    ȱ: 'o',
    Ȳ: 'Y',
    ȳ: 'y',
    ȴ: 'l',
    ȵ: 'n',
    ȶ: 't',
    ȷ: 'j',
    ȸ: 'db',
    ȹ: 'qp',
    Ⱥ: 'A',
    Ȼ: 'C',
    ȼ: 'c',
    Ƚ: 'L',
    Ⱦ: 'T',
    ȿ: 's',
    ɀ: 'z',
    Ƀ: 'B',
    Ʉ: 'U',
    Ʌ: 'V',
    Ɇ: 'E',
    ɇ: 'e',
    Ɉ: 'J',
    ɉ: 'j',
    Ɋ: 'Q',
    ɋ: 'q',
    Ɍ: 'R',
    ɍ: 'r',
    Ɏ: 'Y',
    ɏ: 'y',
    ɐ: 'a',
    ɓ: 'b',
    ɔ: 'o',
    ɕ: 'c',
    ɖ: 'd',
    ɗ: 'd',
    ɘ: 'e',
    ə: 'a',
    ɚ: 'a',
    ɛ: 'e',
    ɜ: 'e',
    ɝ: 'e',
    ɞ: 'e',
    ɟ: 'j',
    ɠ: 'g',
    ɡ: 'g',
    ɢ: 'G',
    ɥ: 'h',
    ɦ: 'h',
    ɨ: 'i',
    ɪ: 'I',
    ɫ: 'l',
    ɬ: 'l',
    ɭ: 'l',
    ɯ: 'm',
    ɰ: 'm',
    ɱ: 'm',
    ɲ: 'n',
    ɳ: 'n',
    ɴ: 'N',
    ɵ: 'o',
    ɶ: 'OE',
    ɼ: 'r',
    ɽ: 'r',
    ɾ: 'r',
    ɿ: 'r',
    ʀ: 'R',
    ʁ: 'R',
    ʂ: 's',
    ʄ: 'j',
    ʇ: 't',
    ʈ: 't',
    ʉ: 'u',
    ʋ: 'v',
    ʌ: 'v',
    ʍ: 'w',
    ʎ: 'y',
    ʏ: 'Y',
    ʐ: 'z',
    ʑ: 'z',
    ʗ: 'C',
    ʙ: 'B',
    ʚ: 'e',
    ʛ: 'G',
    ʜ: 'H',
    ʝ: 'j',
    ʞ: 'k',
    ʟ: 'L',
    ʠ: 'q',
    ʣ: 'dz',
    ʥ: 'dz',
    ʦ: 'ts',
    ʨ: 'tc',
    ʪ: 'ls',
    ʫ: 'lz',
    ʮ: 'h',
    ʯ: 'h',
    ᴀ: 'A',
    ᴁ: 'AE',
    ᴂ: 'ae',
    ᴃ: 'B',
    ᴄ: 'C',
    ᴅ: 'D',
    ᴆ: 'D',
    ᴇ: 'E',
    ᴈ: 'e',
    ᴉ: 'i',
    ᴊ: 'J',
    ᴋ: 'K',
    ᴌ: 'L',
    ᴍ: 'M',
    ᴎ: 'N',
    ᴏ: 'O',
    ᴐ: 'O',
    ᴔ: 'oe',
    ᴕ: 'OU',
    ᴖ: 'o',
    ᴗ: 'o',
    ᴘ: 'P',
    ᴙ: 'R',
    ᴚ: 'R',
    ᴛ: 'T',
    ᴜ: 'U',
    ᴠ: 'V',
    ᴡ: 'W',
    ᴢ: 'Z',
    ᵢ: 'i',
    ᵣ: 'r',
    ᵤ: 'u',
    ᵥ: 'v',
    ᵫ: 'ue',
    ᵬ: 'b',
    ᵭ: 'd',
    ᵮ: 'f',
    ᵯ: 'm',
    ᵰ: 'n',
    ᵱ: 'p',
    ᵲ: 'r',
    ᵳ: 'r',
    ᵴ: 's',
    ᵵ: 't',
    ᵶ: 'z',
    ᵷ: 'g',
    ᵹ: 'g',
    ᵺ: 'th',
    ᵻ: 'I',
    ᵼ: 'i',
    ᵽ: 'p',
    ᵾ: 'U',
    ᶀ: 'b',
    ᶁ: 'd',
    ᶂ: 'f',
    ᶃ: 'g',
    ᶄ: 'k',
    ᶅ: 'l',
    ᶆ: 'm',
    ᶇ: 'n',
    ᶈ: 'p',
    ᶉ: 'r',
    ᶊ: 's',
    ᶌ: 'v',
    ᶍ: 'x',
    ᶎ: 'z',
    ᶏ: 'a',
    ᶑ: 'd',
    ᶒ: 'e',
    ᶓ: 'e',
    ᶔ: 'e',
    ᶕ: 'a',
    ᶖ: 'i',
    ᶗ: 'o',
    ᶙ: 'u',
    Ḁ: 'A',
    ḁ: 'a',
    Ḃ: 'B',
    ḃ: 'b',
    Ḅ: 'B',
    ḅ: 'b',
    Ḇ: 'B',
    ḇ: 'b',
    Ḉ: 'C',
    ḉ: 'c',
    Ḋ: 'D',
    ḋ: 'd',
    Ḍ: 'D',
    ḍ: 'd',
    Ḏ: 'D',
    ḏ: 'd',
    Ḑ: 'D',
    ḑ: 'd',
    Ḓ: 'D',
    ḓ: 'd',
    Ḕ: 'E',
    ḕ: 'e',
    Ḗ: 'E',
    ḗ: 'e',
    Ḙ: 'E',
    ḙ: 'e',
    Ḛ: 'E',
    ḛ: 'e',
    Ḝ: 'E',
    ḝ: 'e',
    Ḟ: 'F',
    ḟ: 'f',
    Ḡ: 'G',
    ḡ: 'g',
    Ḣ: 'H',
    ḣ: 'h',
    Ḥ: 'H',
    ḥ: 'h',
    Ḧ: 'H',
    ḧ: 'h',
    Ḩ: 'H',
    ḩ: 'h',
    Ḫ: 'H',
    ḫ: 'h',
    Ḭ: 'I',
    ḭ: 'i',
    Ḯ: 'I',
    ḯ: 'i',
    Ḱ: 'K',
    ḱ: 'k',
    Ḳ: 'K',
    ḳ: 'k',
    Ḵ: 'K',
    ḵ: 'k',
    Ḷ: 'L',
    ḷ: 'l',
    Ḹ: 'L',
    ḹ: 'l',
    Ḻ: 'L',
    ḻ: 'l',
    Ḽ: 'L',
    ḽ: 'l',
    Ḿ: 'M',
    ḿ: 'm',
    Ṁ: 'M',
    ṁ: 'm',
    Ṃ: 'M',
    ṃ: 'm',
    Ṅ: 'N',
    ṅ: 'n',
    Ṇ: 'N',
    ṇ: 'n',
    Ṉ: 'N',
    ṉ: 'n',
    Ṋ: 'N',
    ṋ: 'n',
    Ṍ: 'O',
    ṍ: 'o',
    Ṏ: 'O',
    ṏ: 'o',
    Ṑ: 'O',
    ṑ: 'o',
    Ṓ: 'O',
    ṓ: 'o',
    Ṕ: 'P',
    ṕ: 'p',
    Ṗ: 'P',
    ṗ: 'p',
    Ṙ: 'R',
    ṙ: 'r',
    Ṛ: 'R',
    ṛ: 'r',
    Ṝ: 'R',
    ṝ: 'r',
    Ṟ: 'R',
    ṟ: 'r',
    Ṡ: 'S',
    ṡ: 's',
    Ṣ: 'S',
    ṣ: 's',
    Ṥ: 'S',
    ṥ: 's',
    Ṧ: 'S',
    ṧ: 's',
    Ṩ: 'S',
    ṩ: 's',
    Ṫ: 'T',
    ṫ: 't',
    Ṭ: 'T',
    ṭ: 't',
    Ṯ: 'T',
    ṯ: 't',
    Ṱ: 'T',
    ṱ: 't',
    Ṳ: 'U',
    ṳ: 'u',
    Ṵ: 'U',
    ṵ: 'u',
    Ṷ: 'U',
    ṷ: 'u',
    Ṹ: 'U',
    ṹ: 'u',
    Ṻ: 'U',
    ṻ: 'u',
    Ṽ: 'V',
    ṽ: 'v',
    Ṿ: 'V',
    ṿ: 'v',
    Ẁ: 'W',
    ẁ: 'w',
    Ẃ: 'W',
    ẃ: 'w',
    Ẅ: 'W',
    ẅ: 'w',
    Ẇ: 'W',
    ẇ: 'w',
    Ẉ: 'W',
    ẉ: 'w',
    Ẋ: 'X',
    ẋ: 'x',
    Ẍ: 'X',
    ẍ: 'x',
    Ẏ: 'Y',
    ẏ: 'y',
    Ẑ: 'Z',
    ẑ: 'z',
    Ẓ: 'Z',
    ẓ: 'z',
    Ẕ: 'Z',
    ẕ: 'z',
    ẖ: 'h',
    ẗ: 't',
    ẘ: 'w',
    ẙ: 'y',
    ẚ: 'a',
    ẛ: 'f',
    ẜ: 's',
    ẝ: 's',
    ẞ: 'SS',
    Ạ: 'A',
    ạ: 'a',
    Ả: 'A',
    ả: 'a',
    Ấ: 'A',
    ấ: 'a',
    Ầ: 'A',
    ầ: 'a',
    Ẩ: 'A',
    ẩ: 'a',
    Ẫ: 'A',
    ẫ: 'a',
    Ậ: 'A',
    ậ: 'a',
    Ắ: 'A',
    ắ: 'a',
    Ằ: 'A',
    ằ: 'a',
    Ẳ: 'A',
    ẳ: 'a',
    Ẵ: 'A',
    ẵ: 'a',
    Ặ: 'A',
    ặ: 'a',
    Ẹ: 'E',
    ẹ: 'e',
    Ẻ: 'E',
    ẻ: 'e',
    Ẽ: 'E',
    ẽ: 'e',
    Ế: 'E',
    ế: 'e',
    Ề: 'E',
    ề: 'e',
    Ể: 'E',
    ể: 'e',
    Ễ: 'E',
    ễ: 'e',
    Ệ: 'E',
    ệ: 'e',
    Ỉ: 'I',
    ỉ: 'i',
    Ị: 'I',
    ị: 'i',
    Ọ: 'O',
    ọ: 'o',
    Ỏ: 'O',
    ỏ: 'o',
    Ố: 'O',
    ố: 'o',
    Ồ: 'O',
    ồ: 'o',
    Ổ: 'O',
    ổ: 'o',
    Ỗ: 'O',
    ỗ: 'o',
    Ộ: 'O',
    ộ: 'o',
    Ớ: 'O',
    ớ: 'o',
    Ờ: 'O',
    ờ: 'o',
    Ở: 'O',
    ở: 'o',
    Ỡ: 'O',
    ỡ: 'o',
    Ợ: 'O',
    ợ: 'o',
    Ụ: 'U',
    ụ: 'u',
    Ủ: 'U',
    ủ: 'u',
    Ứ: 'U',
    ứ: 'u',
    Ừ: 'U',
    ừ: 'u',
    Ử: 'U',
    ử: 'u',
    Ữ: 'U',
    ữ: 'u',
    Ự: 'U',
    ự: 'u',
    Ỳ: 'Y',
    ỳ: 'y',
    Ỵ: 'Y',
    ỵ: 'y',
    Ỷ: 'Y',
    ỷ: 'y',
    Ỹ: 'Y',
    ỹ: 'y',
    Ỻ: 'LL',
    ỻ: 'll',
    Ỽ: 'V',
    Ỿ: 'Y',
    ỿ: 'y',
    '‐': '-',
    '‑': '-',
    '‒': '-',
    '–': '-',
    '—': '-',
    '‘': "'",
    '’': "'",
    '‚': "'",
    '‛': "'",
    '“': '"',
    '”': '"',
    '„': '"',
    '′': "'",
    '″': '"',
    '‵': "'",
    '‶': '"',
    '‸': '^',
    '‹': "'",
    '›': "'",
    '‼': '!!',
    '⁄': '/',
    '⁅': '[',
    '⁆': ']',
    '⁇': '??',
    '⁈': '?!',
    '⁉': '!?',
    '⁎': '*',
    '⁏': ';',
    '⁒': '%',
    '⁓': '~',
    '⁰': '0',
    ⁱ: 'i',
    '⁴': '4',
    '⁵': '5',
    '⁶': '6',
    '⁷': '7',
    '⁸': '8',
    '⁹': '9',
    '⁺': '+',
    '⁻': '-',
    '⁼': '=',
    '⁽': '(',
    '⁾': ')',
    ⁿ: 'n',
    '₀': '0',
    '₁': '1',
    '₂': '2',
    '₃': '3',
    '₄': '4',
    '₅': '5',
    '₆': '6',
    '₇': '7',
    '₈': '8',
    '₉': '9',
    '₊': '+',
    '₋': '-',
    '₌': '=',
    '₍': '(',
    '₎': ')',
    ₐ: 'a',
    ₑ: 'e',
    ₒ: 'o',
    ₓ: 'x',
    ₔ: 'a',
    ↄ: 'c',
    '①': '1',
    '②': '2',
    '③': '3',
    '④': '4',
    '⑤': '5',
    '⑥': '6',
    '⑦': '7',
    '⑧': '8',
    '⑨': '9',
    '⑩': '10',
    '⑪': '11',
    '⑫': '12',
    '⑬': '13',
    '⑭': '14',
    '⑮': '15',
    '⑯': '16',
    '⑰': '17',
    '⑱': '18',
    '⑲': '19',
    '⑳': '20',
    '⑴': '(1)',
    '⑵': '(2)',
    '⑶': '(3)',
    '⑷': '(4)',
    '⑸': '(5)',
    '⑹': '(6)',
    '⑺': '(7)',
    '⑻': '(8)',
    '⑼': '(9)',
    '⑽': '(10)',
    '⑾': '(11)',
    '⑿': '(12)',
    '⒀': '(13)',
    '⒁': '(14)',
    '⒂': '(15)',
    '⒃': '(16)',
    '⒄': '(17)',
    '⒅': '(18)',
    '⒆': '(19)',
    '⒇': '(20)',
    '⒈': '1.',
    '⒉': '2.',
    '⒊': '3.',
    '⒋': '4.',
    '⒌': '5.',
    '⒍': '6.',
    '⒎': '7.',
    '⒏': '8.',
    '⒐': '9.',
    '⒑': '10.',
    '⒒': '11.',
    '⒓': '12.',
    '⒔': '13.',
    '⒕': '14.',
    '⒖': '15.',
    '⒗': '16.',
    '⒘': '17.',
    '⒙': '18.',
    '⒚': '19.',
    '⒛': '20.',
    '⒜': '(a)',
    '⒝': '(b)',
    '⒞': '(c)',
    '⒟': '(d)',
    '⒠': '(e)',
    '⒡': '(f)',
    '⒢': '(g)',
    '⒣': '(h)',
    '⒤': '(i)',
    '⒥': '(j)',
    '⒦': '(k)',
    '⒧': '(l)',
    '⒨': '(m)',
    '⒩': '(n)',
    '⒪': '(o)',
    '⒫': '(p)',
    '⒬': '(q)',
    '⒭': '(r)',
    '⒮': '(s)',
    '⒯': '(t)',
    '⒰': '(u)',
    '⒱': '(v)',
    '⒲': '(w)',
    '⒳': '(x)',
    '⒴': '(y)',
    '⒵': '(z)',
    'Ⓐ': 'A',
    'Ⓑ': 'B',
    'Ⓒ': 'C',
    'Ⓓ': 'D',
    'Ⓔ': 'E',
    'Ⓕ': 'F',
    'Ⓖ': 'G',
    'Ⓗ': 'H',
    'Ⓘ': 'I',
    'Ⓙ': 'J',
    'Ⓚ': 'K',
    'Ⓛ': 'L',
    'Ⓜ': 'M',
    'Ⓝ': 'N',
    'Ⓞ': 'O',
    'Ⓟ': 'P',
    'Ⓠ': 'Q',
    'Ⓡ': 'R',
    'Ⓢ': 'S',
    'Ⓣ': 'T',
    'Ⓤ': 'U',
    'Ⓥ': 'V',
    'Ⓦ': 'W',
    'Ⓧ': 'X',
    'Ⓨ': 'Y',
    'Ⓩ': 'Z',
    'ⓐ': 'a',
    'ⓑ': 'b',
    'ⓒ': 'c',
    'ⓓ': 'd',
    'ⓔ': 'e',
    'ⓕ': 'f',
    'ⓖ': 'g',
    'ⓗ': 'h',
    'ⓘ': 'i',
    'ⓙ': 'j',
    'ⓚ': 'k',
    'ⓛ': 'l',
    'ⓜ': 'm',
    'ⓝ': 'n',
    'ⓞ': 'o',
    'ⓟ': 'p',
    'ⓠ': 'q',
    'ⓡ': 'r',
    'ⓢ': 's',
    'ⓣ': 't',
    'ⓤ': 'u',
    'ⓥ': 'v',
    'ⓦ': 'w',
    'ⓧ': 'x',
    'ⓨ': 'y',
    'ⓩ': 'z',
    '⓪': '0',
    '⓫': '11',
    '⓬': '12',
    '⓭': '13',
    '⓮': '14',
    '⓯': '15',
    '⓰': '16',
    '⓱': '17',
    '⓲': '18',
    '⓳': '19',
    '⓴': '20',
    '⓵': '1',
    '⓶': '2',
    '⓷': '3',
    '⓸': '4',
    '⓹': '5',
    '⓺': '6',
    '⓻': '7',
    '⓼': '8',
    '⓽': '9',
    '⓾': '10',
    '⓿': '0',
    '❛': "'",
    '❜': "'",
    '❝': '"',
    '❞': '"',
    '❨': '(',
    '❩': ')',
    '❪': '(',
    '❫': ')',
    '❬': '<',
    '❭': '>',
    '❮': '"',
    '❯': '"',
    '❰': '<',
    '❱': '>',
    '❲': '[',
    '❳': ']',
    '❴': '{',
    '❵': '}',
    '❶': '1',
    '❷': '2',
    '❸': '3',
    '❹': '4',
    '❺': '5',
    '❻': '6',
    '❼': '7',
    '❽': '8',
    '❾': '9',
    '❿': '10',
    '➀': '1',
    '➁': '2',
    '➂': '3',
    '➃': '4',
    '➄': '5',
    '➅': '6',
    '➆': '7',
    '➇': '8',
    '➈': '9',
    '➉': '10',
    '➊': '1',
    '➋': '2',
    '➌': '3',
    '➍': '4',
    '➎': '5',
    '➏': '6',
    '➐': '7',
    '➑': '8',
    '➒': '9',
    '➓': '10',
    Ⱡ: 'L',
    ⱡ: 'l',
    Ɫ: 'L',
    Ᵽ: 'P',
    Ɽ: 'R',
    ⱥ: 'a',
    ⱦ: 't',
    Ⱨ: 'H',
    ⱨ: 'h',
    Ⱪ: 'K',
    ⱪ: 'k',
    Ⱬ: 'Z',
    ⱬ: 'z',
    Ɱ: 'M',
    Ɐ: 'a',
    ⱱ: 'v',
    Ⱳ: 'W',
    ⱳ: 'w',
    ⱴ: 'v',
    Ⱶ: 'H',
    ⱶ: 'h',
    ⱸ: 'e',
    ⱺ: 'o',
    ⱻ: 'E',
    ⱼ: 'j',
    '⸨': '((',
    '⸩': '))',
    Ꜩ: 'TZ',
    ꜩ: 'tz',
    ꜰ: 'F',
    ꜱ: 'S',
    Ꜳ: 'AA',
    ꜳ: 'aa',
    Ꜵ: 'AO',
    ꜵ: 'ao',
    Ꜷ: 'AU',
    ꜷ: 'au',
    Ꜹ: 'AV',
    ꜹ: 'av',
    Ꜻ: 'AV',
    ꜻ: 'av',
    Ꜽ: 'AY',
    ꜽ: 'ay',
    Ꜿ: 'c',
    ꜿ: 'c',
    Ꝁ: 'K',
    ꝁ: 'k',
    Ꝃ: 'K',
    ꝃ: 'k',
    Ꝅ: 'K',
    ꝅ: 'k',
    Ꝇ: 'L',
    ꝇ: 'l',
    Ꝉ: 'L',
    ꝉ: 'l',
    Ꝋ: 'O',
    ꝋ: 'o',
    Ꝍ: 'O',
    ꝍ: 'o',
    Ꝏ: 'OO',
    ꝏ: 'oo',
    Ꝑ: 'P',
    ꝑ: 'p',
    Ꝓ: 'P',
    ꝓ: 'p',
    Ꝕ: 'P',
    ꝕ: 'p',
    Ꝗ: 'Q',
    ꝗ: 'q',
    Ꝙ: 'Q',
    ꝙ: 'q',
    Ꝛ: 'R',
    ꝛ: 'r',
    Ꝟ: 'V',
    ꝟ: 'v',
    Ꝡ: 'VY',
    ꝡ: 'vy',
    Ꝣ: 'Z',
    ꝣ: 'z',
    Ꝧ: 'TH',
    ꝧ: 'th',
    Ꝩ: 'V',
    Ꝺ: 'D',
    ꝺ: 'd',
    Ꝼ: 'F',
    ꝼ: 'f',
    Ᵹ: 'G',
    Ꝿ: 'G',
    ꝿ: 'g',
    Ꞁ: 'L',
    ꞁ: 'l',
    Ꞃ: 'R',
    ꞃ: 'r',
    Ꞅ: 's',
    ꞅ: 'S',
    Ꞇ: 'T',
    ꟻ: 'F',
    ꟼ: 'p',
    ꟽ: 'M',
    ꟾ: 'I',
    ꟿ: 'M',
    ﬀ: 'ff',
    ﬁ: 'fi',
    ﬂ: 'fl',
    ﬃ: 'ffi',
    ﬄ: 'ffl',
    ﬆ: 'st',
    '！': '!',
    '＂': '"',
    '＃': '#',
    '＄': '$',
    '％': '%',
    '＆': '&',
    '＇': "'",
    '（': '(',
    '）': ')',
    '＊': '*',
    '＋': '+',
    '，': ',',
    '－': '-',
    '．': '.',
    '／': '/',
    '０': '0',
    '１': '1',
    '２': '2',
    '３': '3',
    '４': '4',
    '５': '5',
    '６': '6',
    '７': '7',
    '８': '8',
    '９': '9',
    '：': ':',
    '；': ';',
    '＜': '<',
    '＝': '=',
    '＞': '>',
    '？': '?',
    '＠': '@',
    Ａ: 'A',
    Ｂ: 'B',
    Ｃ: 'C',
    Ｄ: 'D',
    Ｅ: 'E',
    Ｆ: 'F',
    Ｇ: 'G',
    Ｈ: 'H',
    Ｉ: 'I',
    Ｊ: 'J',
    Ｋ: 'K',
    Ｌ: 'L',
    Ｍ: 'M',
    Ｎ: 'N',
    Ｏ: 'O',
    Ｐ: 'P',
    Ｑ: 'Q',
    Ｒ: 'R',
    Ｓ: 'S',
    Ｔ: 'T',
    Ｕ: 'U',
    Ｖ: 'V',
    Ｗ: 'W',
    Ｘ: 'X',
    Ｙ: 'Y',
    Ｚ: 'Z',
    '［': '[',
    '＼': '\\',
    '］': ']',
    '＾': '^',
    '＿': '_',
    ａ: 'a',
    ｂ: 'b',
    ｃ: 'c',
    ｄ: 'd',
    ｅ: 'e',
    ｆ: 'f',
    ｇ: 'g',
    ｈ: 'h',
    ｉ: 'i',
    ｊ: 'j',
    ｋ: 'k',
    ｌ: 'l',
    ｍ: 'm',
    ｎ: 'n',
    ｏ: 'o',
    ｐ: 'p',
    ｑ: 'q',
    ｒ: 'r',
    ｓ: 's',
    ｔ: 't',
    ｕ: 'u',
    ｖ: 'v',
    ｗ: 'w',
    ｘ: 'x',
    ｙ: 'y',
    ｚ: 'z',
    '｛': '{',
    '｝': '}',
    '～': '~',
};
