import { DetailSection } from './detail-section';
import { DetailSet } from './detail-set';
import { ProductDetail } from './product-detail';

export interface Detail {
    id?: string;
    name: string;
    value: string;
    detail_section_id?: string | null;
    fieldType: DetailFieldTypeEnum;
    searchable: boolean;
    measureType?: MeasureType;
    measureUnit?: MeasureUnit;
    detail_section?: DetailSection;
    product_details?: ProductDetail[];
    detail_sets?: DetailSet[];
}

export enum MeasureType {
    GREUTATE = 'greutate',
    VALUTA = 'valuta',
    VOLUM = 'volum',
    TIMP = 'timp',
    CUSTOM = 'orice',
}

export enum MeasureUnit {
    GRAM = 'g',
    KILOGRAM = 'kg',
    RON = 'RON',
    EUR = 'EUR',
    USD = 'USD',
    LITRU = 'l',
    MILILITRU = 'ml',
    BUCATI = 'buc',
    NA = 'N/A',
    PROCENT = '%',
    ORE = 'ore',
    MINUTE = 'minute',
    SECUNDE = 'secunde',
}

export enum DetailFieldTypeEnum {
    text = 'text',
    numar = 'numar',
    lista = 'lista',
}

export interface DetailSearch {
    name: string;
    value: string[] | number[];
    fieldType: DetailFieldTypeEnum;
}

export interface Range {
    min: number;
    max: number;
}

export const MeasureUnitOfMeasureUnit = {
    [MeasureType.GREUTATE]: [MeasureUnit.GRAM, MeasureUnit.KILOGRAM],
    [MeasureType.VALUTA]: [MeasureUnit.RON, MeasureUnit.EUR, MeasureUnit.USD],
    [MeasureType.VOLUM]: [MeasureUnit.LITRU, MeasureUnit.MILILITRU],
    [MeasureType.TIMP]: [MeasureUnit.ORE, MeasureUnit.MINUTE, MeasureUnit.SECUNDE],
    [MeasureType.CUSTOM]: [],
};
