import { CurrencyEnum } from './currency';
import { ProductStatusEnum } from './product';
import { User } from './user';

export interface Notification {
    id?: string;
    created_at?: Date;
    updated_at?: Date;
    message?: string;
    severity: NotificationSeverityEnum;
    section: NotificationSectionEnum;
    type: NotificationTypeEnum;
    name: string;
    linkInfo?: any;
    owner?: string;
    users: User[];
}

export interface Log {
    level: 'info' | 'error' | 'warning';
    message: string;
    providerId?: string;
    providerName?: string;
    sku?: string;
    product?: { name?: string; status?: ProductStatusEnum; price?: number; discounted_price?: number; stock?: number; currency?: CurrencyEnum };
    error?: any;
    progress?: number;
    syncCount?: {
        success: number;
        error: number;
    };
    timestamp?: string;
}

export interface UserNotification {
    id?: string;
    user?: User;
    notification: Notification;
    read: boolean;
}

export enum NotificationSeverityEnum {
    Info = 'Info',
    Warning = 'Warning',
    Critical = 'Critical',
}

export enum NotificationSectionEnum {
    Order = 'Order',
    OrderPaid = 'OrderPaid',
    Product = 'Product',
    Invoice = 'Invoice',
    InvoiceByCard = 'InvoiceByCard',
    Sincronizare_furnizor = 'Sincronizare furnizor',
}

export enum NotificationTypeEnum {
    Create = 'Create',
    Delete = 'Delete',
    Upgrade = 'Upgrade',
    Analyze = 'Analyze',
}
