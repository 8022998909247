export interface Banner {
    id?: string;
    name: string;
    image: string;
    imageMobile: string;
    from?: string;
    due?: string;
    url?: string;
    priority: number;
    location: BannerLocationEnum;
    active: boolean;
}

export enum BannerLocationEnum {
    Main = 'Main',
    Catalog = 'Catalog',
    Product = 'Product',
}

export const BannerLocationRatios = {
    Main: {
        width: 7,
        height: 3,
    },
    Catalog: {
        width: 10,
        height: 1,
    },
    Product: {
        width: 10,
        height: 1,
    },
};
